<template>
  <div>
    <router-link class="come-back" to="/admin/connect_contacts">
      <div></div>
      Вернуться назад
    </router-link>

    <h2 class="title_categories">Редактирование контакта</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="createContact" ref="createContact">
      <div class="inputs__container">
        <div class="content-sb">
          <p>Адрес<span>*</span></p>
          <input required placeholder="Введите адрес" v-model="contactsBlocks.address" type="text" name="address" class="input blue">
        </div>
        <div class="content-sb">
          <p>Адрес (Англ)<span>*</span></p>
          <input required placeholder="Введите адрес (Англ)" v-model="contactsBlocks.address_eng" type="text" name="address_eng" class="input blue">
        </div>
        <div class="content-sb">
          <p>Адрес сайта<span>*</span></p>
          <input required placeholder="Введите адрес сайта" v-model="contactsBlocks.site" type="text" name="site" class="input blue">
        </div>
        <div class="content-sb">
          <p>Email<span>*</span></p>
          <input required placeholder="Введите email" v-model="contactsBlocks.email" type="email" name="email" class="input blue">
        </div>
      </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/connect_contacts" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'

export default {
  name: 'InternetReceptionCreate',

  data () {
    return {
      contactsBlocks: [
        {
          site: '',
          email: '',
          address: '',
          address_eng: ''
        }
      ]
    }
  },

  watch: {
    '$store.getters.getInternetReception' () {
      const dataFromServer = this.$store.getters.getInternetReception
      this.contactsBlocks = {
        site: dataFromServer.site,
        email: dataFromServer.email,
        address: dataFromServer.address,
        address_eng: dataFromServer.address_eng
      }
    }
  },

  created () {
    this.$store.dispatch('setInternetReception')
  },

  methods: {
    createContact () {
      const formData = new FormData(this.$refs.createContact)
      //   formData.append('site', this.contactsBlocks.site)
      //   formData.append('email', this.contactsBlocks.email)
      //   formData.append('address', this.contactsBlocks.address)
      this.$store.dispatch('editInternetReception', formData)
    }
  },
  components: {
    TricolorLine
  }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";

  .wysiwyg {
    width: 33.75rem;
  }

  .contacts__block {
    border-bottom: 1px solid #D5D6D9;
    margin-bottom: 1rem;

    header {
      margin-bottom: .75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > h4 {
        font-size: .875rem;
        line-height: 1.375rem;
      }

      > img {
        width: 1.5rem;
        height: 1.5rem;
        object-fit: contain;
        object-position: center;
        cursor: pointer;
      }
    }
  }

  .btns-create-form {
    border-top: 1px solid #D5D6D9;
    padding-top: 2.5rem;
    margin-top: 2.5rem;
  }

  .content-sb {
    margin-bottom: 1.25rem;

    span {
      color: red;
    }
  }

  .select, .input {
    width: 33.75rem;
  }

  .inputs__container {
    margin-bottom: 2rem;
    border-bottom: 1px solid #D5D6D9;
  }

  .content-blocks__container {
    display: flex;
    flex-direction: column;

    > .content-block {
      margin-bottom: 1.875rem;

      header {
        margin-bottom: .75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h4 {
          font-size: .875rem;
          line-height: 1.375rem;
          color: $mainFontColor;
        }

        > img {
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
          cursor: pointer;
        }
      }

      .data-card {
        background: white;
        border: 1px solid #EBF3FF;
        box-sizing: border-box;
        box-shadow: 0 .9375rem 1.5625rem rgba(189, 205, 221, 0.15);
        border-radius: .5rem;
        padding: 1.25rem;
        width: 100%;
        min-height: 8.875rem;
      }
    }
  }

  .add-content-block__container {
    background-color: $neutral-placeholder;
    width: 100%;
    padding: 1.25rem;
    border-radius: .187rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: .5rem;

    &:after {
      content: '';
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      background-color: $neutral-placeholder;
      top: 0;
      left: 50%;
      transform: translate(-50%,-50%) rotate(45deg);
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.25rem;
    }

    > .btn-blue > img {
      width: 1.125rem;
      height: 1.125rem;
      object-fit: contain;
      object-position: center;
      margin-right: .5rem;
    }
  }
</style>
